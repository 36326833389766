<template>
    <v-card>
        <v-card-text>
            <v-row>
                <v-col>
                    <div class="font-weight-bold mt-6 title-page" tabindex="0">Login</div>
                    <p class="mt-2" tabindex="0">
                        Realize o login para acessar a Área do Credor.
                    </p>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="validForm">
                <v-row>
                    <v-col>
                        <v-text-field dense id="username" label="E-mail" v-model="dataset.data.email" required
                            :rules="[$rules.required]" outlined hide-details="auto" aria-label="Area de texto para digitar seu email de acesso ao site">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field dense outlined label="Senha" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            required :rules="[$rules.required]" :type="showPassword ? 'text' : 'password'"
                            v-model="dataset.data.password" @click:append="showPassword = !showPassword" hide-details="auto"
                            class="mb-4" id="password" aria-label="Area de texto para digitar a senha de acesso ao site"
                            ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <p tabindex="0">
                            Esqueceu sua senha? <a href="#" @click.prevent="openForgotPassword" tabindex="0" 
                            aria-label="Caso não se lembre mais de sua senha de acesso, este é o link para recuperar sua senha">Clique aqui</a>
                        </p>
                        <p tabindex="0">
                            Você ainda não possui uma conta? <a href="#" @click.prevent="openRegisterDialog"
                            aria-label="Caso não tenha efetuado cadastro no site, este é o link para realizar o cadastro">Clique aqui</a>
                        </p>
                    </v-col>
                </v-row>
            </v-form>
            <v-row>
                <v-col>
                    <v-btn color="primary" @click.native="login" :loading="loading" tabindex="0" 
                        aria-label="Botão para entrar no site depois de digitar o login e senha" >Entrar</v-btn>
                    <v-btn color="grey" text @click.native="goBack" tabindex="0" 
                        aria-label="Botão para retornar ao site sem fazer login">Cancelar</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import 'moment/locale/pt-br';

import { eventHub } from "@/main";

import axios from "axios"

import router from '../../router';

export default {
    data: () => ({
        showPassword: false,
        validForm: false,
        loading: false,
        dataset: {
            data: {}
        }
    }),

    mounted: function () {
    },

    created() {
    },

    methods: {

        openRegisterDialog() {
            eventHub.$emit('DIALOG-REGISTER', true)
        },

        openForgotPassword() {
            this.goBack();
            eventHub.$emit('DIALOG-FORGOT-PASSWORD', true)
        },

        goBack() {
            eventHub.$emit('DIALOG-ACCESS', false)
        },

        async login() {

            if (!this.$refs.form.validate()) return;

            let data = {
                email: this.dataset.data.email,
                password: this.dataset.data.password,
            };

            this.loading = true;

            try {
                const result = await axios.post('/api/login', data);

                if (!result.data.status) throw new Error();

                localStorage.setItem('token', JSON.stringify(result.data.content.token));

                const dataUser = { name: result.data.content.name };
                this.$root.user = dataUser;

                localStorage.setItem('user', JSON.stringify(dataUser));

                this.goBack();

                router.go("/home");

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
                this.dataset.data.password = "";
            } finally {
                this.loading = false;
            }
        },

    }
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

p {
    text-align: justify;
}
</style>
