const data = [
   // { name: "RESUMO GERAL", route: "/resumo-geral" },
    { name: "MINHA CONTA", route: "/minha-conta" },
    { name: "HABILITAÇÕES / DIVERGÊNCIAS", route: "/habilitacoes-divergencias" },
    { name: "MODELOS DE DOCUMENTOS", route: "/modelos-documentos" },
   // { name: "ASSEMBLEIA DE CREDORES", route: "/assembleia-credores" },
    { name: "CENTRAL DE DOCUMENTOS", route: "/central-documentos" },
];

export default [...data];
